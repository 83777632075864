import { AnyAction } from 'redux'

import { IPaginationResponse, IPriceListState, IElectricityRateItem, IGasRateItem } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IPriceListState = {
  electricityList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  electricityDetails: undefined,
  gasList: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  gasDetails: undefined
}

const priceListReducer = (state = initialState, action: AnyAction): IPriceListState => {
  switch (action.type) {
    case actionTypes.GET_ELECTRICITY_RATES_SUCCESS: {
      const response: IPaginationResponse<IElectricityRateItem> = action.response?.data
      if (!response) return state
      const totalCount =
        action.payload?.filter && Object.keys(action.payload.filter).length
          ? state.electricityList.totalCount
          : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        electricityList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_GAS_RATES_SUCCESS: {
      const response: IPaginationResponse<IGasRateItem> = action.response?.data
      if (!response) return state
      const totalCount =
        action.payload?.filter && Object.keys(action.payload.filter).length
          ? state.gasList.totalCount
          : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        gasList: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.GET_ELECTRICITY_PRICE_DETAIL_SUCCESS: {
      const electricityDetails: IElectricityRateItem = action.response?.data
      return { ...state, electricityDetails }
    }

    case actionTypes.GET_GAS_PRICE_DETAIL_SUCCESS: {
      const gasDetails: IGasRateItem = action.response?.data
      return { ...state, gasDetails }
    }

    default:
      return state
  }
}

export default priceListReducer
