import { AnyAction } from 'redux'

import { IEic, IEicAction, IEicDetails, IEicState, IMonthlyVolume, IPaginationResponse } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IEicState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  actions: [],
  details: undefined,
  monthlyVolumes: []
}

const eicReducer = (state = initialState, action: AnyAction): IEicState => {
  switch (action.type) {
    case actionTypes.GET_EIC_SUCCESS: {
      const response: IPaginationResponse<IEic> = action.response?.data?.paginated
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.SET_DELIVERY_END_EIC_SUCCESS:
    case actionTypes.SET_DELIVERY_START_EIC_SUCCESS:
    case actionTypes.GET_EIC_DETAIL_SUCCESS: {
      const details: IEicDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.UPDATE_EIC_DETAIL_SUCCESS: {
      const details: IEicDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.CREATE_EIC_SUCCESS: {
      const details: IEicDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.GET_EIC_ACTIONS_SUCCESS: {
      const actions: IEicAction[] = action.response?.data
      return { ...state, actions }
    }

    case actionTypes.GET_EIC_MONTHLY_VOLUMES_TRIGGERED: {
      return { ...state, monthlyVolumes: initialState.monthlyVolumes }
    }

    case actionTypes.GET_EIC_MONTHLY_VOLUMES_SUCCESS: {
      const monthlyVolumes: IMonthlyVolume[] = action.response?.data
      return { ...state, monthlyVolumes }
    }

    default:
      return state
  }
}

export default eicReducer
