import { AnyAction } from 'redux'

import { IAuthState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IAuthState = {
  user: undefined
}

const authReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.UPDATE_ME_SUCCESS:
    case actionTypes.ME_SUCCESS: {
      return {
        ...state,
        user: action.response?.data
      }
    }

    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.response?.data?.user
      }
    }

    default:
      return state
  }
}

export default authReducer
