import { DefaultTheme } from 'styled-components'

const customTheme: Partial<DefaultTheme> = {
  colors: {
    background: '#EFEEE7',
    white: {
      default: '#FFFFFE'
    },
    blue: {
      default: '#386663',
      light: '#BBECE8',
      lighter: '#d9f2f0'
    },
    green: {
      default: '#3F4D36',
      light: '#B6F573',
      lighter: '#dcf6c1'
    },
    teal: {
      default: '#375F55',
      light: '#dafde4'
    },
    orange: {
      default: '#4B384E',
      light: '#FFDCC1'
    },
    yellow: {
      default: '#ffa300'
    },
    red: {
      default: '#ff3b30'
    }
  },
  typography: {
    fontFamily: ['Instrument Sans', 'Arial', 'sans-serif'].join(','),
    secondaryFontFamily: 'Azeret Mono',
    sizes: {
      body: {
        xsmall: 9,
        small: 10,
        medium: 14,
        large: 16,
        xlarge: 20
      },
      headings: {
        xsmall: 15, // h5
        small: 17, // h4
        medium: 21, // h3
        large: 25, // h2
        xlarge: 48 // h1
      }
    }
  }
}

export default customTheme
