import { AnyAction } from 'redux'

import {
  IContract,
  IContractDetails,
  IContractsState,
  IContractStatus,
  IPaginationResponse
} from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IContractsState = {
  list: {
    totalCount: 0,
    data: {
      items: [],
      pagination: undefined
    },
    rememberedListProps: undefined
  },
  statuses: [],
  invoiceGroups: [],
  details: undefined,
  suggestContractNumber: undefined
}

const contractsReducer = (state = initialState, action: AnyAction): IContractsState => {
  switch (action.type) {
    case actionTypes.GET_CONTRACTS_SUCCESS: {
      const response: IPaginationResponse<IContract> = action.response?.data
      if (!response) return state
      const totalCount = Object.keys(action.payload.filter).length ? state.list.totalCount : response.meta.totalItems
      const data = {
        items: response.data,
        pagination: response.meta
      }
      return {
        ...state,
        list: { totalCount, data, rememberedListProps: action.payload }
      }
    }

    case actionTypes.PROCESS_CONTRACT_SUCCESS:
    case actionTypes.UNPROCESS_CONTRACT_SUCCESS:
    case actionTypes.UPDATE_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.CREATE_CONTRACT_SUCCESS:
    case actionTypes.CREATE_CONTRACT_AMENDMENT_SUCCESS:
    case actionTypes.GET_CONTRACT_DETAIL_SUCCESS:
    case actionTypes.SET_TERMINATION_DATE_CONTRACT_SUCCESS:
    case actionTypes.SET_APPROVE_CONTRACT_SUCCESS:
    case actionTypes.POST_DRAFT_CONTRACT_DELIVERY_POINTS_SUCCESS:
    case actionTypes.POST_CONTRACT_DELIVERY_POINTS_SUCCESS: {
      const details: IContractDetails = action.response?.data
      return { ...state, details }
    }

    case actionTypes.GET_CONTRACTS_STATUSES_SUCCESS: {
      const statuses: IContractStatus[] = action.response?.data
      return {
        ...state,
        // eslint-disable-next-line fp/no-mutating-methods
        statuses: statuses?.sort((statusA, statusB) => statusA.id - statusB.id)
      }
    }

    case actionTypes.GET_CONTRACT_INVOICE_GROUPS_TRIGGERED: {
      return {
        ...state,
        invoiceGroups: initialState.invoiceGroups
      }
    }

    case actionTypes.GET_CONTRACT_INVOICE_GROUPS_SUCCESS: {
      return {
        ...state,
        invoiceGroups: action.response?.data
      }
    }

    case actionTypes.GET_CONTRACT_NUMBER_SUCCESS:
    case actionTypes.GET_TRADER_CONTRACT_NUMBER_SUCCESS: {
      return {
        ...state,
        suggestContractNumber: action.response?.data.newContractNumber
      }
    }

    default:
      return state
  }
}

export default contractsReducer
