import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@otion-core/sandy'

import { ColorDot } from 'src/components/commonStyled'
import { BankAccountStatusEnum } from 'src/shared/interfaces'

interface BankAccountStatusProps {
  value?: string
}

const BankAccountStatus = (props: BankAccountStatusProps) => {
  const { t } = useTranslation()

  if (!props.value) return null

  let color

  switch (props.value) {
    case BankAccountStatusEnum.NOT_PAIRED:
      color = '#ff3b30'
      break
    case BankAccountStatusEnum.PAIRED:
      color = '#8abd25'
      break
    case BankAccountStatusEnum.INCORRECT_PAYMENT:
      color = '#424242'
      break
  }

  return (
    <Container as='span' style={{ display: 'inline-flex' }} alignItems='center'>
      <ColorDot color={color} />
      <span>{t(`BANK_ACCOUNT_STATUS.${props.value}` as never)}</span>
    </Container>
  )
}

BankAccountStatus.displayName = 'BankAccountStatus'
export default BankAccountStatus
