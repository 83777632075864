import { AnyAction } from 'redux'

import { IDashboardsState } from 'src/shared/interfaces'

import * as actionTypes from '../actions'

const initialState: IDashboardsState = {
  electricity: {
    portfolio: undefined
  },
  trade: {
    zipCodes: undefined
  }
}

const dashboardsReducer = (state = initialState, action: AnyAction): IDashboardsState => {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_ELECTRICITY_SUCCESS: {
      if (!action.response?.data) return state
      return {
        ...state,
        electricity: {
          ...state.electricity,
          ...action.response?.data
        }
      }
    }

    case actionTypes.GET_DASHBOARD_TRADE_SUCCESS: {
      if (!action.response?.data) return state
      return {
        ...state,
        trade: {
          ...state.trade,
          ...action.response?.data
        }
      }
    }

    default:
      return state
  }
}

export default dashboardsReducer
