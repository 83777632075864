import React from 'react'
import { AppPage, Typography, Grid, PolygonalGrid } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'

import { AdministrationIcon, ElectricityIcon, GasIcon, TradingIcon, FinancialsIcon } from 'src/assets/icons'

import { HeaderRightAdornment } from '../../components'
import * as S from './styles'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <AppPage title={t('HOME')} headerTitle={t('HOME')} headerRightAdornment={<HeaderRightAdornment />} hideBackButton>
      <PolygonalGrid>
        <Grid.Item>
          <S.Toolbox
            to='/administration'
            icon={<AdministrationIcon />}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {t('ADMINISTRATION')}
              </Typography>
            }
            size='large'
            disabled
          />
        </Grid.Item>
        <Grid.Item>
          <S.Toolbox
            backgroundColor='green.light'
            to='/electricity'
            icon={<ElectricityIcon />}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {t('ELECTRICITY')}
              </Typography>
            }
            size='large'
          />
        </Grid.Item>
        <Grid.Item>
          <S.Toolbox
            backgroundColor='teal.light'
            to='/trade'
            icon={<TradingIcon />}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {t('TRADE')}
              </Typography>
            }
            size='large'
          />
        </Grid.Item>
        <Grid.Item>
          <S.Toolbox
            backgroundColor='orange.light'
            to='/financials'
            icon={<FinancialsIcon />}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {t('FINANCIALS')}
              </Typography>
            }
            size='large'
          />
        </Grid.Item>
        <Grid.Item>
          <S.Toolbox
            backgroundColor='blue.light'
            to='/gas'
            icon={<GasIcon />}
            text={
              <Typography variant='heading' size='small' weight='medium' inline>
                {t('GAS')}
              </Typography>
            }
            size='large'
          />
        </Grid.Item>
      </PolygonalGrid>
    </AppPage>
  )
}

HomePage.displayName = 'HomePage'
export default HomePage
