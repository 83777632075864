import React, { useEffect, useState } from 'react'
import ReactMapboxGl, { ZoomControl, Source, Layer } from 'react-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { IDashboardTradeZipCode } from 'src/shared/interfaces'

interface MapBoxProps {
  lookupAddress?: string
  coordinates?: number[]
  markers?: IDashboardTradeZipCode[]
}

const SMALL_MARKER = 40
const MEDIUM_MARKER = 90

const MapBox = ({ lookupAddress, coordinates, markers }: MapBoxProps) => {
  const [location, setLocation] = useState<GeocodeFeature>()
  const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN

  useEffect(() => {
    if (lookupAddress && mapboxToken && !coordinates) fetchGeoData(lookupAddress)
  }, [lookupAddress])

  if (!mapboxToken) {
    console.error('Unable to read mapbox access token')
    return null
  }

  const Map = ReactMapboxGl({ accessToken: mapboxToken })

  const fetchGeoData = async (addressString: string) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      addressString
    )}.json?types=address&limit=1&access_token=${mapboxToken}`
    const geocode: GeocodeResponse = await fetch(url)
      .then(res => res.json())
      .catch(err => console.log(err))
    if (geocode?.features && geocode?.features[0]) setLocation(geocode.features[0])
  }

  const parsedCoordinates = coordinates || location?.center
  if (!parsedCoordinates && !markers?.length) return null

  // Convert markers to GeoJSON
  const geojsonData = markers
    ? {
        type: 'FeatureCollection',
        features: markers.map(marker => ({
          type: 'Feature',
          properties: {
            count: marker.count
          },
          geometry: {
            type: 'Point',
            coordinates: [marker.longitude, marker.latitude]
          }
        }))
      }
    : null

  return (
    <Map
      style='mapbox://styles/mapbox/streets-v11'
      center={markers ? [19.5454, 48.7345] : (parsedCoordinates as never)}
      zoom={[7]}
      containerStyle={{ height: '100%', width: '100%' }}
    >
      <ZoomControl />

      <React.Fragment>
        {geojsonData && (
          <React.Fragment>
            <Source
              id='markers'
              geoJsonSource={{
                type: 'geojson',
                data: geojsonData,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                clusterProperties: {
                  // Sum up the 'count' property as 'sum'
                  sum: ['+', ['get', 'count']]
                }
              }}
            />

            {/* Cluster Shadow */}
            <Layer
              id='cluster-shadow'
              type='circle'
              sourceId='markers'
              filter={['has', 'point_count']}
              paint={{
                'circle-color': 'rgba(0, 0, 0, 0.25)', // Shadow color with opacity
                'circle-radius': [
                  'step',
                  ['get', 'sum'],
                  18, // Slightly larger than the smallest cluster
                  SMALL_MARKER,
                  21, // Slightly larger than the medium cluster
                  MEDIUM_MARKER,
                  24 // Slightly larger than the largest cluster
                ],
                'circle-blur': 1, // Adds a blur effect to the circle
                'circle-translate': [0, 12], // Moves the shadow slightly downward
                'circle-translate-anchor': 'viewport'
              }}
            />

            {/* Clustered Points */}
            <Layer
              id='clusters'
              type='circle'
              sourceId='markers'
              filter={['has', 'point_count']}
              paint={{
                'circle-color': ['step', ['get', 'sum'], '#f8e03f', SMALL_MARKER, '#ffaa16', MEDIUM_MARKER, '#fb5c53'],
                'circle-radius': ['step', ['get', 'sum'], 14, SMALL_MARKER, 17, MEDIUM_MARKER, 20]
              }}
            />

            {/* Cluster Sum Labels */}
            <Layer
              id='cluster-count'
              type='symbol'
              sourceId='markers'
              filter={['has', 'point_count']}
              layout={{
                'text-field': '{sum}',
                'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
                'text-size': 12
              }}
            />

            {/* Unclustered Shadow */}
            <Layer
              id='unclustered-shadow'
              type='circle'
              sourceId='markers'
              filter={['!', ['has', 'point_count']]}
              paint={{
                'circle-color': 'rgba(0, 0, 0, 0.25)', // Shadow color with opacity
                'circle-radius': [
                  'step',
                  ['get', 'count'],
                  18, // Slightly larger than the smallest cluster
                  SMALL_MARKER,
                  21, // Slightly larger than the medium cluster
                  MEDIUM_MARKER,
                  24 // Slightly larger than the largest cluster
                ],
                'circle-blur': 1, // Adds a blur effect to the circle
                'circle-translate': [0, 12], // Moves the shadow slightly downward
                'circle-translate-anchor': 'viewport'
              }}
            />

            {/* Unclustered Point Circles */}
            <Layer
              id='unclustered-point-circle'
              type='circle'
              sourceId='markers'
              filter={['!', ['has', 'point_count']]}
              paint={{
                'circle-color': [
                  'step',
                  ['get', 'count'],
                  '#f8e03f',
                  SMALL_MARKER,
                  '#ffaa16',
                  MEDIUM_MARKER,
                  '#fb5c53'
                ],
                'circle-radius': ['step', ['get', 'count'], 14, SMALL_MARKER, 17, MEDIUM_MARKER, 20]
              }}
            />

            {/* Unclustered Points with Counts */}
            <Layer
              id='unclustered-point'
              type='symbol'
              sourceId='markers'
              filter={['!', ['has', 'point_count']]}
              layout={{
                'text-field': '{count}',
                'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
                'text-size': 12,
                'text-offset': [0, -0.5],
                'text-anchor': 'top'
              }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </Map>
  )
}

MapBox.displayName = 'MapBox'
export default MapBox
