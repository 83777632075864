import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Modal, Select, Typography } from '@otion-core/sandy'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

import { useNotifications } from 'src/shared/hooks'
import { assignCustomerToUser, getCustomersWithoutAccount, getUserDetails } from 'src/redux/actions'
import { CustomerStatusEnum, IAssignCustomerPayload, ICustomerSimple, Option } from 'src/shared/interfaces'

interface Props {
  onClose: () => void
}

const validationSchema = Yup.object().shape({
  user_id: Yup.string().required(),
  customer_id: Yup.string().required()
})

const AssignCustomerToUserModal = ({ onClose }: Props) => {
  const dispatch = useDispatch()
  const params = useParams<{ id: string }>()
  const userId = params.id as string
  const { t: tCommon } = useTranslation()
  const { showError, showSuccess } = useNotifications()
  const [usersList, setUsersList] = useState<Option[]>([])

  const handleClose = () => {
    onClose()
  }

  const fetchSimpleCustomers = async () => {
    const res = await dispatch(getCustomersWithoutAccount())
    if (res?.data?.length) {
      setUsersList(
        res.data?.map((customer: ICustomerSimple) => {
          let statusIcon = ''
          if (customer.status === CustomerStatusEnum.NEW) statusIcon = '⏳ '
          if (customer.status === CustomerStatusEnum.INACTIVE) statusIcon = '🚫 '
          return {
            value: customer.id.toString(),
            text: `${statusIcon}${customer.customer}`
          }
        }) || []
      )
    }
  }

  useEffect(() => {
    fetchSimpleCustomers()
  }, [])

  const onSubmitHandler = async (values: IAssignCustomerPayload, helpers: FormikHelpers<IAssignCustomerPayload>) => {
    helpers.setSubmitting(true)
    const response = await dispatch(assignCustomerToUser(values))
    helpers.setSubmitting(false)
    if (response?.data?.id) {
      await dispatch(getUserDetails(userId))
      handleClose()
      showSuccess('Customer assigned successfully')
      helpers.resetForm()
    } else {
      showError(tCommon('forms.unsuccessful'), response)
      console.error(response)
    }
  }

  const initialValues: IAssignCustomerPayload = {
    user_id: userId,
    customer_id: ''
  }

  return (
    <Formik
      initialValues={initialValues as IAssignCustomerPayload}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {({ isSubmitting, submitForm, errors, isValid }: FormikProps<IAssignCustomerPayload>) => {
        return (
          <Form>
            <Modal title='Priradiť subjecta' open closeHandler={handleClose}>
              <Container style={{ width: 500 }} top='medium'>
                <Field
                  name='customer_id'
                  as={Select}
                  error={Boolean(errors?.customer_id)}
                  width='full'
                  options={usersList}
                  enableReset
                  searchable
                />

                <Container fullWidth top='xlarge'>
                  <Container flex justifyContent='space-between'>
                    <Button
                      size='xsmall'
                      color='white'
                      fullWidth
                      style={{ fontSize: '13px', marginRight: '50px', width: '200px', minWidth: '200px' }}
                      onClick={handleClose}
                    >
                      <Typography size='medium' weight='semibold'>
                        {tCommon('CANCEL')}
                      </Typography>
                    </Button>
                    <Container flex fullWidth justifyContent='flex-end'>
                      <Button
                        size='xsmall'
                        variant='primary'
                        color='yellow.light'
                        fullWidth
                        style={{ maxWidth: '200px', fontSize: '13px' }}
                        onClick={submitForm}
                        loading={isSubmitting}
                        disabled={isSubmitting || !isValid}
                      >
                        <Typography size='medium' weight='semibold'>
                          {tCommon('SAVE')}
                        </Typography>
                      </Button>
                    </Container>
                  </Container>
                </Container>
              </Container>
            </Modal>
          </Form>
        )
      }}
    </Formik>
  )
}

AssignCustomerToUserModal.displayName = 'AssignCustomerToUserModal'
export default AssignCustomerToUserModal
