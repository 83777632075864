/* eslint-disable no-shadow */
import { ICreateCustomerPayload, ICustomer, ICustomerDetails } from './customers.interface'
import { EicStatusEnum, ICreateEicDraftPayload, IEic } from './eic.interface'

export interface IContract {
  commodity_type: CommodityTypeEnum
  company_id: string
  contract_number: string
  customer: string
  customer_id: string | ICustomer
  date_of_sign?: string
  delivery_period_start?: string
  delivery_period_end?: string
  delivery_point_count: number
  error_status?: number
  id: number
  rzm_kwh: number
  status?: number
  price_type?: string
  price_vt_eur_mwh?: number
  spot_add_value?: number
  spot_coef?: number
  other_services_eur_om_month?: number
  fms_eur_om_month?: number
}

export enum InvoiceGroupingType {
  TOGETHER = 'TOGETHER',
  SEPARATELY = 'SEPARATELY',
  CUSTOM = 'CUSTOM'
}

export interface IContractDetails {
  commodity_type: CommodityTypeEnum
  company_id: string
  contract_number: string
  customer?: string
  customer_id: string
  date_of_sign?: string
  delivery_period_end: string
  delivery_period_start: string
  delivery_point_count: number
  evaluation_period?: string
  fms_eur_om_month?: number
  id: number
  invoicing_email?: string
  other_services_eur_om_month?: number
  penalty_for_exceeding_minus_individually_agreed?: number
  penalty_for_exceeding_plus_individually_agreed?: number
  price_type?: string
  price_vt_eur_mwh?: number
  processed: boolean
  rzm_kwh: number
  spot_add_value?: number
  spot_coef?: number
  status?: number
  take_or_pay_minus?: number
  take_or_pay_plus?: number
  termination_date: string
  trader_id?: number
  // ---------
  amendments?: IContractAmendment[]
  contract_type: '1' | '2'
  created_at?: string
  delivery_points?: (IContractEicDeliveryPoint | IContractPodDeliveryPoint)[]
  initial_contract_values?: {
    id: number
    status: number
    delivery_period_end: string
    delivery_period_start: string
    created_at: string
  }
  parent_contract_id?: number
}

// TODO: make more specific description
export interface IContractAmendment extends Partial<IContractDetails> {
  id: number
  contract_id: number
}

export interface IContractEicDeliveryPoint {
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  reserved_capacity: number
  competition?: string
  contract_id: number
  created_at: string
  created_by?: number
  delivery_end?: string
  delivery_start?: string
  distribution_system: string
  eic?: string
  eic_id?: number
  pod?: string
  pod_id?: number
  id: number
  notice_period_for_termination_months?: string
  number_of_phases: number
  rate: string
  rzm: number
  status: EicStatusEnum
  tariff_category: string
  type_of_measurement: string
  updated_at: string
  updated_by: number
  voltage_level: string
}

export interface IContractPodDeliveryPoint {
  address_city: string
  address_number: string
  address_street: string
  address_zip_code: string
  contract_number: number
  customer: string
  delivery_end: string
  delivery_start: string
  type_of_measurement?: string
  pod: string
  id: number
  pod_id: number
  rzm: number
  status: EicStatusEnum
}

export interface IUploadContractFile {
  file?: File
}

export const importHeadersCSV = [
  'contractNumber',
  'companyId',
  'email',
  'iban',
  'swift',
  'contactName',
  'contactPhone',
  'contactEmail',
  'priceType',
  'price',
  'fms',
  'contractDeliveryStart',
  'contractDeliveryEnd',
  'eic',
  'rzmKWh',
  'deliveryStart',
  'deliveryPointCity',
  'deliveryPointStreet',
  'deliveryPointStreetNumber',
  'deliveryPointZipCode',
  'distributionRate',
  'reservedCapacityValue',
  'numberOfPhases',
  'voltageLevel'
]

export interface IUploadContractResponse {
  contractsExist: IContract[]
  contractsCreated: IContract[]
  customersCreated: ICustomerDetails[]
  eicsCreated: IEic[]
}
export enum CONTRACT_PRICE_TYPE {
  FIX = 'FIX',
  SPOT_ADD = 'SPOT_ADD',
  SPOT_COEF = 'SPOT_COEF'
}

export enum CommodityTypeEnum {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS'
}
export enum CONTRACT_STATUS {
  NEW = 1,
  WAITING_FOR_DELIVERY,
  ACTIVE_DELIVERY,
  TERMINATED_DELIVERY,
  CONTRACT_CANCELED,
  DRAFT
}

export interface IContractDeliveryPointPayload {
  id: number
  rzm: string | number
  eic?: string
  pod?: string
  status?: EicStatusEnum
  type_of_measurement?: string
  delivery_end?: string
}

export interface ICreateContractPayload {
  contract_number: string
  customer_id: string
  delivery_period_start: string | Date
  delivery_period_end: string | Date
  delivery_points: IContractDeliveryPointPayload[]
  price_type: CONTRACT_PRICE_TYPE
  commodity_type: CommodityTypeEnum
}

export interface ICreateContractDraftPayload extends Omit<ICreateContractPayload, 'customer_id' | 'delivery_points'> {
  price_vt_eur_mwh?: number
  spot_add_value?: number
  spot_coef?: number
  other_services_eur_om_month?: number
  fms_eur_om_month?: number
  customer?: ICreateCustomerPayload
  delivery_points?: ICreateEicDraftPayload[]
}

export interface ICreateContractAmendmentPayload {
  delivery_period_start: string | Date
  delivery_period_end: string | Date
  price_type: CONTRACT_PRICE_TYPE
}

export interface ISetTerminationDateContractPayload {
  termination_date: string | Date
}

export interface IContractStatus {
  id: number
  name: string
}

export interface IContractsFilter {
  id?: number
  search?: string
  status?: number
  unprocessed_amendments?: boolean
  commodity_type?: CommodityTypeEnum
}

export interface IInvoiceGroup {
  amount_of_proforma_invoice_percent: number
  contract_id: number
  due_day_of_proforma_invoices?: number
  due_day_of_reconciliation_invoices?: number
  e_invoicing: boolean
  delivery_points?: number[]
  email_for_invoicing?: string
  group_code: number | string
  id?: number
  payment_method?: PAYMENT_METHOD
  iban?: string
  swift?: string
}

export interface IInvoiceGroupsFormValues {
  e_invoicing: string
  delivery_points?: number[]
  email_for_invoicing?: string
  amount_of_proforma_invoice_percent?: string
  due_day_of_proforma_invoices?: string
  due_day_of_reconciliation_invoices?: string
  payment_method?: string
  iban?: string
  swift?: string
  invoice_groups?: IInvoiceGroup[]
}

export interface IContractDeliveryPointsPayload {
  delivery_points?: IContractDeliveryPointPayload[]
}

export interface IContractDraftDeliveryPointsPayload {
  delivery_points?: ICreateEicDraftPayload[]
}

export interface IPostInvoiceGroupsPayload {
  invoice_groups: IInvoiceGroup[]
}

export enum CURVE_TYPE {
  BALANCED = 'BALANCED',
  RUN_IN = 'RUN_IN',
  CURVE = 'CURVE'
}

export enum PAYMENT_METHOD {
  PAYMENT = 'PAYMENT',
  INKASO = 'INKASO'
}

export enum EVALUATION_PERIOD {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export interface ISetContractNumberResponse {
  newContractNumber: string
}

export interface IGetCompanyCribis {
  companyId: string
}

export interface IBasicInfoCribisResponseDataDto {
  companyName: string
  addressStreet: string
  addressCity: string
  addressZip: string
  companyId: string
  taxId?: string
  vatId?: string
  creationDate: string
  addressLatitude: number
  addressLongitude: number
}

export interface Item {
  id: number
  value: string
}

export interface ICreateCustomerContactsPayload {
  phone: string
  email: string
  fullName: string
}
