import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppPage, Button, Card, Container, Typography } from '@otion-core/sandy'

import { ConnectionItem, ConnectionsScrollBox, HeaderRightAdornment, Loader, PromptModal } from 'src/components'
import { UserInfoCard } from 'src/components/Users/UserInfoCard'
import { CardBox } from 'src/components/commonStyled'
import { getUserDetails, removeUserById, signInAs, unassignCustomerFromUser } from 'src/redux/actions'
import { useNotifications } from 'src/shared/hooks'
import { userDetails$ } from 'src/redux/selectors'
import { IUserDetails, UserRole } from 'src/shared/interfaces'
import { ContractIcon, PlusIcon } from 'src/assets/icons'
import { setToken } from 'src/utils'

import AssignCustomerToUserModal from './AssignCustomerToUserModal'

const UserDetailsPage = () => {
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { showError } = useNotifications()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [openAddingCustomer, setOpenAddingCustomer] = useState(false)
  const [openPromptModal, setOpenPromptModal] = useState(false)
  const [openSignInAsModal, setOpenSignInAsModal] = useState<string>()

  const userId = params.id as string

  const userDetails = useSelector(userDetails$)

  const getUserDetailsHandler = async (id: string) => {
    setLoading(true)
    const detailResponse = await dispatch(getUserDetails(id))
    const user = detailResponse.data as IUserDetails
    if (!user?.id) showError('Could not fetch user details', detailResponse)
    setLoading(false)
  }

  useEffect(() => {
    getUserDetailsHandler(userId)
  }, [userId])

  const handleClickOnRemoveUserButton = () => {
    setOpenPromptModal(true)
  }

  const removeUser = async () => {
    await dispatch(removeUserById(userId))
    navigate(`/settings`)
  }

  const handleSignInAs = async (email: string) => {
    const res = await dispatch(signInAs({ email }))
    setToken(res?.data?.access_token)
    window.location.reload()
  }

  const handleUnassignCustomerFromUser = async (user_id: string, customer_id: string) => {
    return dispatch(unassignCustomerFromUser({ user_id, customer_id }))
  }

  if (loading) {
    return (
      <Container flex justifyContent='center'>
        <Loader />
      </Container>
    )
  }

  if (!userDetails) {
    return (
      <Container flex justifyContent='center' top='xlarge'>
        <Typography font='secondary'>Chyba pri načítavaní detailu</Typography>
      </Container>
    )
  }

  return (
    <AppPage
      title={t('USER_INFO')}
      headerTitle={t('USER_INFO')}
      headerRightAdornment={<HeaderRightAdornment />}
      backButtonText={t('SETTINGS')}
      backButtonLocation='/settings'
    >
      <UserInfoCard
        userDetails={userDetails}
        currentUserRole={UserRole.ADMIN}
        setOpenSignInAsModal={setOpenSignInAsModal}
      />

      <Container fullWidth top={1}>
        <Card heading={t('users.keyInfo.customers')}>
          <ConnectionsScrollBox>
            <CardBox
              color='orange'
              icon={<PlusIcon height='45px' />}
              size='medium'
              onClick={e => {
                e.stopPropagation()
                setOpenAddingCustomer(true)
              }}
            />
            {userDetails?.customers?.map(customer => (
              <ConnectionItem
                key={customer.id}
                icon={<ContractIcon />}
                text={customer.customer}
                to={`/electricity/portfolio/customers/${customer?.id}`}
                onDelete={() => handleUnassignCustomerFromUser(userId, customer.id.toString())}
                deletePrompt='Unassign customer from the user?'
              />
            ))}
          </ConnectionsScrollBox>
        </Card>
      </Container>

      <Container flex justifyContent='center' top='large' fullWidth>
        <Button
          variant='outlined'
          color='red'
          size='small'
          fullWidth={false}
          stopPropagation
          onClick={handleClickOnRemoveUserButton}
        >
          {t('users.deleteUser')}
        </Button>
      </Container>
      {openAddingCustomer && <AssignCustomerToUserModal onClose={() => setOpenAddingCustomer(false)} />}
      {openPromptModal && (
        <PromptModal
          color='white'
          title={t('users.deleteUser')}
          description={t('users.deleteUserConfirmMessage')}
          closeHandler={() => setOpenPromptModal(false)}
          onConfirm={removeUser}
          dangerConfirm
        />
      )}
      {openSignInAsModal ? (
        <PromptModal
          color='yellow'
          closeHandler={() => setOpenSignInAsModal(undefined)}
          onConfirm={() => handleSignInAs(openSignInAsModal)}
          title={t('users.signInAs')}
          description={
            <React.Fragment>
              {t('users.signInAsConfirmMessage')} <b>{openSignInAsModal}</b>.
            </React.Fragment>
          }
        />
      ) : null}
    </AppPage>
  )
}

UserDetailsPage.displayName = 'UserDetailsPage'
export default UserDetailsPage
